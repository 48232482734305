<template>
  <ASkeletonLoader
    width="100%"
    height="56px"
    radius="0"
  />
</template>

<script>
import ASkeletonLoader from 'theme/components/atoms/loaders/a-skeleton-loader';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'MProductAddToCartSkeletonLoader',
  components: {
    ASkeletonLoader
  }
});
</script>
