<template>
  <MProductCarousel
    v-if="relatedProducts.length"
    list-id="related_products"
    list-name="Related products"
    :products="relatedProducts"
    :arrow-color="arrowColor"
    :carousel-key="relatedProducts.length"
    :es-url-param="esUrlParam"
    :show-qty-permanently="showQtyPermanently"
    :alt-counter-offset="altCounterOffset"
    :alt-name="altName"
  />
</template>

<script>
import MProductCarousel from './m-product-carousel';
import { mapActions, mapGetters, mapState } from 'vuex';
import { prepareRelatedQuery } from 'theme/helpers/overrideQueries';
import config from 'config';
import modulesConfig from '$modules/config'

export default {
  name: 'MProductsRelated',
  components: {
    MProductCarousel
  },
  props: {
    altCounterOffset: {
      type: Number,
      default: 0
    },
    altName: {
      type: String,
      default: ''
    },
    arrowColor: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      required: true
    },
    propSkus: {
      type: Array,
      default: () => [],
      required: false
    },
    onlyInStock: {
      type: Boolean,
      default: false
    },
    esUrlParam: {
      type: String,
      default: ''
    },
    showQtyPermanently: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      esputnikProducts: [],
      linkedProducts: [],
      categoryProducts: []
    }
  },
  computed: {
    ...mapState({
      currentShipping: state => state['shipping-module'].current,
      loadedShipping: state => state['shipping-module'].loaded.shipping
    }),
    ...mapGetters({
      getCurrentProduct: 'product/getCurrentProduct'
    }),
    shippingChangeTrigger () {
      return {
        shopId: this.currentShipping?.shop?.id,
        method: this.currentShipping?.method,
        newPostRef: this.currentShipping?.npShop?.ref,
        loaded: this.loadedShipping
      }
    },
    productLinks () {
      return this.getCurrentProduct.product_links;
    },
    relatedProducts () {
      if (this.esputnikProducts.length) return this.esputnikProducts
      if (this.linkedProducts.length) return this.linkedProducts
      return this.categoryProducts
    }
  },
  watch: {
    propSkus (newValue, oldValue) {
      const valueChanged = JSON.stringify(newValue) !== JSON.stringify(oldValue)
      if (!newValue || !newValue.length || !valueChanged) return
      this.refreshList()
    },
    shippingChangeTrigger: {
      handler: async function (newValue, oldValue) {
        if (
          !newValue?.loaded || (
            newValue?.shopId && (
              newValue?.shopId === oldValue?.shopId &&
              newValue?.method === oldValue?.method &&
              newValue?.newPostRef === oldValue?.newPostRef &&
              newValue?.loaded === oldValue?.loaded
            )
          )
        ) return

        this.refreshList()
      }
    },
    relatedProducts (value) {
      this.$emit('visibilityUpdate', value.length > 0)
      this.$emit('relatedProductsQty', this.relatedProducts.length)
    }
  },
  beforeMount () {
    if (config.usePriceTiers) {
      this.$bus.$on('user-after-loggedin', this.refreshList);
      this.$bus.$on('user-after-logout', this.refreshList);
    }

    this.refreshList();
  },
  beforeDestroy () {
    if (config.usePriceTiers) {
      this.$bus.$off('user-after-loggedin', this.refreshList);
      this.$bus.$off('user-after-logout', this.refreshList);
    }
  },
  methods: {
    ...mapActions({
      findProducts: 'product/findProducts'
    }),
    async refreshList () {
      this.esputnikProducts = []
      this.linkedProducts = []
      this.categoryProducts = []

      let sku = this.propSkus || []
      let key = 'sku';

      if (sku.length) {
        const relatedProductsQuery = prepareRelatedQuery(key, sku);
        this.esputnikProducts = await this.getRelatedProducts(key, sku, relatedProductsQuery)
        if (this.esputnikProducts.length) return
      }
      if (this.type === 'buyWithOrInsteadProduct') return

      sku = this.productLinks
        ? this.productLinks.filter(pl => pl.link_type === this.type).map(pl => pl.linked_product_sku)
        : []

      if (sku.length) {
        const relatedProductsQuery = prepareRelatedQuery(key, sku);
        this.linkedProducts = await this.getRelatedProducts(key, sku, relatedProductsQuery)
        if (this.linkedProducts.length) return
      }

      const categories = this.getCurrentProduct?.category || []
      sku = categories.filter(e => e.is_product_category && e.level > 2).map(e => e.category_id) || [];
      key = 'category_ids'

      if (sku.length) {
        const endCategorySku = sku.slice(-1)
        const relatedProductsQuery = prepareRelatedQuery(key, endCategorySku);
        relatedProductsQuery.applyFilter({
          key: 'sku',
          value: { 'neq': this.getCurrentProduct.sku }
        });
        this.categoryProducts = await this.getRelatedProducts(key, endCategorySku, relatedProductsQuery)
      }
    },
    async getRelatedProducts (key, sku, relatedProductsQuery) {
      const response = await this.findProducts({
        query: relatedProductsQuery,
        size: config.entities.product.carouselSize,
        includeFields: modulesConfig.smallProduct.includeFields,
        excludeFields: modulesConfig.smallProduct.excludeFields,
        skipLoadOptions: true,
        prefetchGroupProducts: false,
        updateState: false,
        onlyInStock: this.onlyInStock
      });

      return response?.items || []
    }
  }
};
</script>
