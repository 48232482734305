<template>
  <div class="m-product-gallery">
    <div v-if="productStock.isInStock">
      <NoSSR>
        <Badges :product="product"/>
      </NoSSR>
      <NoSSR>
        <div v-if="svgLabels.length">
          <SfOImage
            v-for="(label, index) in svgLabels"
            class="product__svg-label"
            :class="'product__svg-label--position-' + (index + 1)"
            :src="label"
            :key="'product_label_' + index + '_' + product.id"
            :alt="`Фото ${index + 1} - ${product.name}`"
          />
        </div>
      </NoSSR>
    </div>
    <NoSSR>
      <AAddToWishlist :product="product" />
    </NoSSR>
    <template v-if="hasMultipleImages">
      <SfOCarousel
        class="slider-carousel"
        :settings="sliderSettings"
        ref="slider"
        :is-show-bullets="true"
        :is-show-arrows="false"
      >
        <SfCarouselItem
          v-for="(item, index) in preparedGallery"
          :key="item.id"
        >
          <SfOImage
            ref="gallery"
            :src="preparedGallery[index]"
            :alt="`Фото ${index + 1} - ${product.name}`"
            :lazy="false"
            :hide-loader="true"
          />
        </SfCarouselItem>
        <template #bullets="{ numberOfPages, page, go }">
          <SfBullets
            :total="numberOfPages"
            :current="page - 1"
            data-transaction-name="Carousel - Bullet Click"
            @click="go($event)"
          >
            <template #inactive="{ index, go }">
              <li :key="index">
                <SfButton
                  :aria-label="'Go to slide ' + (index + 1)"
                  class="sf-button--pure sf-bullet bullet__thumbnail"
                  @click="go(index)"
                >
                  <SfOImage
                    v-if="isDesktop"
                    ref="gallery"
                    :src="preparedThumbs[index]"
                    :alt="`Фото ${index + 1} - ${product.name}`"
                    :lazy="true"
                    :hide-loader="true"
                  />
                </SfButton>
              </li>
            </template>
            <template #active="{ current }">
              <li>
                <SfButton
                  aria-label="Current slide"
                  class="sf-button--pure sf-bullet sf-bullet--active bullet__thumbnail"
                >
                  <SfOImage
                    v-if="isDesktop"
                    ref="gallery"
                    :src="preparedThumbs[current]"
                    :alt="`Фото ${page} - ${product.name}`"
                    :lazy="false"
                    :hide-loader="true"
                  />
                </SfButton>
              </li>
            </template>
          </SfBullets>
        </template>
      </SfOCarousel>
    </template>
    <template v-else>
      <SfOImage
        ref="gallery"
        :src="preparedGallery[0]"
        :alt="`Фото 1 - ${product.name}`"
        :lazy="false"
        :hide-loader="true"
      />
    </template>
  </div>
</template>

<script>
import isEqual from 'lodash/isEqual';
import { SfBullets, SfButton } from '@storefront-ui/vue';
import { getProductCarouselImagePath, getProductCarouselThumbPath, getProductImagePath } from 'theme/helpers';
import DeviceType from 'theme/mixins/DeviceType';
import ProductLabels from 'theme/mixins/ProductLabels';
import SfOImage from 'theme/components/storefront-override/SfOImage';
import NoSSR from 'vue-no-ssr';
import SfOCarousel from 'theme/components/storefront-override/SfOCarousel.vue';

export const SIZE_PRODUCT_GALLERY = {
  desktop: {
    width: 1140,
    height: 1140
  },
  mobile: {
    width: 670,
    height: 670
  }
}

export default {
  name: 'MProductGallery',
  mixins: [ DeviceType, ProductLabels ],
  components: {
    SfOCarousel,
    AAddToWishlist: () => process.browser ? import('theme/components/atoms/a-add-to-wishlist') : null,
    SfOImage,
    NoSSR,
    Badges: () => process.browser ? import('theme/components/product/badges') : null,
    SfBullets,
    SfButton
  },
  props: {
    product: {
      type: Object,
      default: () => ({})
    },
    productStock: {
      type: Object,
      default: () => ({})
    },
    gallery: {
      type: Array,
      required: true
    },
    configuration: {
      type: Object,
      required: true
    },
    sliderSettings: {
      type: Object,
      default: () => {
        return {
          type: 'slider',
          animationDuration: 400,
          animationTimingFunc: 'ease-in-out',
          dragThreshold: true,
          perView: 1
        };
      }
    }
  },
  computed: {
    hasMultipleImages () {
      return this.product && Array.isArray(this.product.fv_index) && this.product.fv_index.length > 0;
    },
    size () {
      return SIZE_PRODUCT_GALLERY
    },
    preparedThumbs () {
      return this.product.fv_index.map(item => {
        return getProductCarouselThumbPath(this.product.sku, item.index)
      });
    },
    preparedGallery () {
      if (this.hasMultipleImages) {
        return this.product.fv_index.map(item => {
          return {
            mobile: {
              url: getProductCarouselImagePath(this.product.sku, this.size.mobile.width, this.size.mobile.height, item.index),
              alt: this.product.name
            },
            desktop: {
              url: getProductCarouselImagePath(this.product.sku, this.size.desktop.width, this.size.desktop.height, item.index),
              alt: this.product.name
            }
          }
        });
      } else {
        return [{
          mobile: {
            url: getProductImagePath(this.product.sku, this.size.mobile.width, this.size.mobile.height),
            alt: this.product.name
          },
          desktop: {
            url: getProductImagePath(this.product.sku, this.size.desktop.width, this.size.desktop.height),
            alt: this.product.name
          }
        }]
      }
    },
    variantImage () {
      let variantImage = this.gallery.find(image => {
        let selectThis = true
        for (const [key, value] of Object.entries(this.configuration)) {
          if (
            typeof image.id !== 'undefined' &&
            typeof image.id[key] !== 'undefined' &&
            image.id[key] !== value.id
          ) {
            selectThis = false
          }
        }
        return selectThis || (image.id && image.id.color && String(image.id.color) === String(this.configuration.color.id))
      })

      if (!variantImage) {
        variantImage = this.gallery.find(image => {
          return typeof image.id.color !== 'undefined' &&
            typeof this.configuration.color !== 'undefined' &&
            String(image.id.color) === String(this.configuration.color.id)
        })
      }

      if (!variantImage) {
        variantImage = this.gallery[0];
      }

      return variantImage;
    },
    currentIndex () {
      const index = this.gallery.findIndex(imageObject =>
        isEqual(imageObject.id, this.variantImage.id)
      );

      return index === -1 ? 0 : index;
    }
  },
  watch: {
    currentIndex () {
      this.$refs.gallery.go(this.currentIndex)
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/px2rem";

.product__svg-label {
  width: var(--spacer-70);
  height: var(--spacer-70);
  position: absolute!important;
  bottom: var(--spacer-5);
  z-index: 2;

  &--position-1 {
    right: var(--spacer-5);
  }

  &--position-2 {
    right: var(--spacer-80);
  }
}

.a-add-to-wishlist {
  position: absolute;
  top: 9px;
  right: 7px;
  z-index: 1;
}

.m-product-gallery {
  position: relative;
  max-width: 590px;
  display: flex;
  justify-content: center;
  align-items: center;
  isolation: isolate;

  @media (max-width: $mobile-max) {
    min-height: 300px;
  }

  @media (min-width: $tablet-min) {
    margin-right:  var(--spacer-10);
    max-width: 300px;
  }

  @media (min-width: $desktop-min) {
    max-width: 590px;
    flex: 0 1 590px;
  }

  .slider-carousel .sf-carousel__wrapper{
    .sf-image {
      justify-content: center;
      display: flex;
      align-items: center;

      @media (min-width: $desktop-min) {
        width: 100%;
        height: auto;
        max-width: 570px;
        max-height: 570px;
      }

      ::v-deep img {
        object-fit: contain;
        max-width: 300px;
        max-height: 300px;

        @media (min-width: $desktop-min) {
          max-width: 570px;
          max-height: 570px;
        }
      }
    }

    .sf-image:not(.product__svg-label) {
      padding: var(--spacer-10);
      position: static;

      picture {
        display: block;
      }

      @media (min-width: $tablet-min) {
        width: 17.5rem;
        height: auto;
      }

      @media (min-width: $desktop-min) {
        width: auto;
        height: auto;
        max-width: 570px;
        max-height: 570px;
      }

      .sf-image--has-size {
        img:not(.noscript) {
          position: static;
          transform: none;
          padding: var(--spacer-10);
        }

        &:after {
          padding-bottom: 0;
        }
      }
    }
  }
}
.bullet__thumbnail {
  ::v-deep {
    .sf-image img {
      display: none;
    }
  }

  @media (min-width: $desktop-min) {
    box-sizing: border-box;
    padding: 10px;
    --bullet-height: 60px;
    --bullet-width: 60px;
    --bullet-background: var(--white);
    --bullet-border-radius: 0;

    ::v-deep {
      .sf-image img {
        display: inline-block;
        object-fit: contain;
        --image-width: 40px;
        --image-height: 40px;
      }
      &.sf-bullet {
        opacity: 0.7;
      }
      &.sf-bullet:hover, &.sf-bullet--active {
        --bullet-border-radius: 0;
        opacity: 1;
        transform: none;
      }
    }
  }
}

::v-deep {
  .sfo-carousel__bullets {
    @media (min-width: $desktop-min) {
      padding: 0;
      position: relative;
      top: -68px;
    }
  }
}
</style>
